// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index_contentWrapper__ug7\\+c {
  color: #1d2129;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  width: 236px;
}
.index_infoTitle__oPbpL {
  margin-bottom: 16px;
}
.index_infoTitle__oPbpL img {
  width: 14px;
  height: 14px;
  margin-right: 8px;
}
.index_btn__3t63F {
  height: 24px;
  border-radius: 2px;
  text-align: center;
  line-height: 24px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  outline: none;
  border: none;
}
.index_btns__Jz-Ah {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  cursor: pointer;
}
.index_cancel__wxyr3 {
  height: 24px;
  border-radius: 2px;
  text-align: center;
  line-height: 24px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  outline: none;
  border: none;
  background: #f2f3f8;
  color: #1d2129;
  margin-right: 8px;
}
.index_confirm__zMv9h {
  height: 24px;
  border-radius: 2px;
  text-align: center;
  line-height: 24px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  outline: none;
  border: none;
  background: #1664ff;
  color: #ffffff;
}
`, "",{"version":3,"sources":["webpack://./src/components/PopoverContent/index.module.less"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,0BAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,YAAA;AACF;AAEA;EACE,mBAAA;AAAF;AADA;EAIM,WAAA;EACA,YAAA;EACA,iBAAA;AAAN;AAKA;EACE,YAAA;EACA,kBAAA;EACA,kBAAA;EACA,iBAAA;EACA,0BAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,aAAA;EACA,YAAA;AAHF;AAMA;EACE,aAAA;EACA,yBAAA;EACA,gBAAA;EACA,eAAA;AAJF;AAOA;EAnBE,YAAA;EACA,kBAAA;EACA,kBAAA;EACA,iBAAA;EACA,0BAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,aAAA;EACA,YAAA;EAYA,mBAAA;EACA,cAAA;EACA,iBAAA;AAIF;AADA;EA1BE,YAAA;EACA,kBAAA;EACA,kBAAA;EACA,iBAAA;EACA,0BAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,aAAA;EACA,YAAA;EAoBA,mBAAA;EACA,cAAA;AAWF","sourcesContent":[".contentWrapper {\n  color: #1d2129;\n  font-family: 'PingFang SC';\n  font-style: normal;\n  font-weight: 400;\n  font-size: 12px;\n  width: 236px;\n}\n\n.infoTitle {\n  margin-bottom: 16px;\n  :global {\n    img {\n      width: 14px;\n      height: 14px;\n      margin-right: 8px;\n    }\n  }\n}\n\n.btn {\n  height: 24px;\n  border-radius: 2px;\n  text-align: center;\n  line-height: 24px;\n  font-family: 'PingFang SC';\n  font-style: normal;\n  font-weight: 400;\n  font-size: 12px;\n  outline: none;\n  border: none;\n}\n\n.btns {\n  display: flex;\n  justify-content: flex-end;\n  margin-top: 16px;\n  cursor: pointer;\n}\n\n.cancel {\n  .btn();\n  background: #f2f3f8;\n  color: #1d2129;\n  margin-right: 8px;\n}\n\n.confirm {\n  .btn();\n\n  background: #1664ff;\n  color: #ffffff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentWrapper": `index_contentWrapper__ug7+c`,
	"infoTitle": `index_infoTitle__oPbpL`,
	"btn": `index_btn__3t63F`,
	"btns": `index_btns__Jz-Ah`,
	"cancel": `index_cancel__wxyr3`,
	"confirm": `index_confirm__zMv9h`
};
export default ___CSS_LOADER_EXPORT___;
