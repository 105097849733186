// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index_menuButton__obN3Q {
  position: relative;
  width: 88px;
  height: 54px;
  margin-right: 8px;
  text-align: center;
  border-radius: 8px;
  -webkit-user-select: none;
          user-select: none;
}
.index_menuButton__obN3Q:hover {
  background: rgba(255, 255, 255, 0.05);
}
`, "",{"version":3,"sources":["webpack://./src/scene/Edub/components/RecordButton/index.module.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,kBAAA;EACA,yBAAA;UAAA,iBAAA;AACF;AACE;EACE,qCAAA;AACJ","sourcesContent":[".menuButton {\n  position: relative;\n  width: 88px;\n  height: 54px;\n  margin-right: 8px;\n  text-align: center;\n  border-radius: 8px;\n  user-select: none;\n\n  &:hover {\n    background: rgba(255, 255, 255, 5%);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuButton": `index_menuButton__obN3Q`
};
export default ___CSS_LOADER_EXPORT___;
