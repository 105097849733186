// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index_headerRight__oDgPX {
  display: flex;
}
.index_stopWrapper__pZCP9 .ant-popover-content {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}
.index_stopWrapper__pZCP9 .ant-popover-content .ant-popover-arrow {
  transform: translate(15px, -100%);
}
`, "",{"version":3,"sources":["webpack://./src/scene/Edub/Room/HeaderRight/index.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AAEA;EAGM,2CAAA;AAFN;AADA;EAMQ,iCAAA;AAFR","sourcesContent":[".headerRight {\n  display: flex;\n}\n\n.stopWrapper {\n  :global {\n    .ant-popover-content {\n      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);\n\n      .ant-popover-arrow {\n        transform: translate(15px, -100%);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerRight": `index_headerRight__oDgPX`,
	"stopWrapper": `index_stopWrapper__pZCP9`
};
export default ___CSS_LOADER_EXPORT___;
