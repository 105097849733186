// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index_linkedUserWrapper__Ke9dk {
  display: flex;
  flex-direction: column;
  min-width: 177px;
  max-width: 177px;
  padding-top: 7px;
  overflow-y: auto;
}
`, "",{"version":3,"sources":["webpack://./src/scene/Edub/Room/LinkedUserList/index.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,gBAAA;EACA,gBAAA;EACA,gBAAA;AACF","sourcesContent":[".linkedUserWrapper {\n  display: flex;\n  flex-direction: column;\n  min-width: 177px;\n  max-width: 177px;\n  padding-top: 7px;\n  overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"linkedUserWrapper": `index_linkedUserWrapper__Ke9dk`
};
export default ___CSS_LOADER_EXPORT___;
