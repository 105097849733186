// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index_container__Bmf\\+n {
  position: relative;
  padding-top: 50px;
  width: 100%;
  height: 100%;
}
.index_preview-player__12w0O {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #000;
}
.index_player__\\+0n5y {
  width: 100%;
  height: 100%;
}
.index_cameraImg__QD0Z6 {
  position: absolute;
  top: 25%;
  transform: scale(0.4);
}
.index_backToScene__gv\\+J6 {
  position: absolute;
  top: 70px;
  left: 32px;
  width: 48px;
  height: 48px;
  cursor: pointer;
}
.index_backToScene__gv\\+J6 img {
  width: 48px;
  height: 48px;
}
`, "",{"version":3,"sources":["webpack://./src/components/JoinRoom/index.module.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,iBAAA;EACA,WAAA;EACA,YAAA;AACF;AAEA;EACE,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;EACA,sBAAA;AAAF;AAGA;EACE,WAAA;EACA,YAAA;AADF;AAIA;EACE,kBAAA;EACA,QAAA;EACA,qBAAA;AAFF;AAKA;EACE,kBAAA;EACA,SAAA;EACA,UAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;AAHF;AAHA;EASM,WAAA;EACA,YAAA;AAHN","sourcesContent":[".container {\n  position: relative;\n  padding-top: 50px;\n  width: 100%;\n  height: 100%;\n}\n\n.preview-player {\n  position: relative;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  height: 100%;\n  background-color: #000;\n}\n\n.player {\n  width: 100%;\n  height: 100%;\n}\n\n.cameraImg {\n  position: absolute;\n  top: 25%;\n  transform: scale(0.4);\n}\n\n.backToScene {\n  position: absolute;\n  top: 70px;\n  left: 32px;\n  width: 48px;\n  height: 48px;\n  cursor: pointer;\n  :global {\n    img {\n      width: 48px;\n      height: 48px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `index_container__Bmf+n`,
	"preview-player": `index_preview-player__12w0O`,
	"player": `index_player__+0n5y`,
	"cameraImg": `index_cameraImg__QD0Z6`,
	"backToScene": `index_backToScene__gv+J6`
};
export default ___CSS_LOADER_EXPORT___;
