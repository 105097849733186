// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index_control__L0R6z {
  position: absolute;
  bottom: 16px;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  padding: 0 10px;
  color: #fff;
  font-size: 12px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 4px;
  transform: translateX(-50%);
}
.index_pageope__Rq2LR {
  margin-right: 5px;
  padding: 10px;
  background: #383838;
}
.index_pagination__2wQnr {
  margin: 0 5px;
  vertical-align: middle;
}
.index_pagination__2wQnr .button {
  cursor: pointer;
}
.index_previewBtn__h7j4T {
  height: 18px;
  color: #fff;
  font-weight: 400;
  font-size: 13px;
  font-family: 'PingFang SC';
  font-style: normal;
  line-height: 18px;
  text-align: center;
  background: transparent;
  border: none;
  box-shadow: none;
}
.index_sperature__86-jB {
  display: block;
  width: 1px;
  height: 12px;
  margin: 0 8px;
  background: #fff;
  opacity: 0.2;
}
`, "",{"version":3,"sources":["webpack://./src/components/WhiteBoard/BottomControl/index.module.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,YAAA;EACA,SAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,eAAA;EACA,WAAA;EACA,eAAA;EACA,8BAAA;EACA,kBAAA;EACA,2BAAA;AACF;AAEA;EACE,iBAAA;EACA,aAAA;EACA,mBAAA;AAAF;AAGA;EACE,aAAA;EACA,sBAAA;AADF;AADA;EAMM,eAAA;AAFN;AAOA;EACE,YAAA;EACA,WAAA;EACA,gBAAA;EACA,eAAA;EACA,0BAAA;EACA,kBAAA;EACA,iBAAA;EACA,kBAAA;EACA,uBAAA;EACA,YAAA;EACA,gBAAA;AALF;AAQA;EACE,cAAA;EACA,UAAA;EACA,YAAA;EACA,aAAA;EACA,gBAAA;EACA,YAAA;AANF","sourcesContent":[".control {\n  position: absolute;\n  bottom: 16px;\n  left: 50%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 28px;\n  padding: 0 10px;\n  color: #fff;\n  font-size: 12px;\n  background: rgba(0, 0, 0, 60%);\n  border-radius: 4px;\n  transform: translateX(-50%);\n}\n\n.pageope {\n  margin-right: 5px;\n  padding: 10px;\n  background: #383838;\n}\n\n.pagination {\n  margin: 0 5px;\n  vertical-align: middle;\n\n  :global {\n    .button {\n      cursor: pointer;\n    }\n  }\n}\n\n.previewBtn {\n  height: 18px;\n  color: #fff;\n  font-weight: 400;\n  font-size: 13px;\n  font-family: 'PingFang SC';\n  font-style: normal;\n  line-height: 18px;\n  text-align: center;\n  background: transparent;\n  border: none;\n  box-shadow: none;\n}\n\n.sperature {\n  display: block;\n  width: 1px;\n  height: 12px;\n  margin: 0 8px;\n  background: #fff;\n  opacity: 0.2;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"control": `index_control__L0R6z`,
	"pageope": `index_pageope__Rq2LR`,
	"pagination": `index_pagination__2wQnr`,
	"previewBtn": `index_previewBtn__h7j4T`,
	"sperature": `index_sperature__86-jB`
};
export default ___CSS_LOADER_EXPORT___;
