// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index_edubWrapper__eChqx {
  width: calc(100% - 8px);
  height: 100%;
  background: var(--background-container);
  flex: 1 1;
  position: relative;
  margin-right: 8px;
}
.index_boardWrapper__Rw1-i {
  display: flex;
  width: 100%;
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/scene/Edub/Room/EdubView/index.module.less"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,YAAA;EACA,uCAAA;EACA,SAAA;EACA,kBAAA;EACA,iBAAA;AACF;AAEA;EACE,aAAA;EACA,WAAA;EACA,YAAA;AAAF","sourcesContent":[".edubWrapper {\n  width: calc(100% - 8px);\n  height: 100%;\n  background: var(--background-container);\n  flex: 1;\n  position: relative;\n  margin-right: 8px;\n}\n\n.boardWrapper {\n  display: flex;\n  width: 100%;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"edubWrapper": `index_edubWrapper__eChqx`,
	"boardWrapper": `index_boardWrapper__Rw1-i`
};
export default ___CSS_LOADER_EXPORT___;
