// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index_viewWrapper__ihBSo {
  height: calc(100% - 50px);
  overflow: hidden;
  margin-top: 50px;
  background: #dce0e2;
  display: flex;
}
.index_content__oTq3e {
  height: 100%;
  width: calc(100% - 248px);
  display: flex;
  flex-direction: column;
}
.index_contentTop__Xj5l- {
  height: calc(100% - 64px);
  width: 100%;
  display: flex;
  padding-right: 7px;
}
`, "",{"version":3,"sources":["webpack://./src/scene/Edub/Room/index.module.less"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,gBAAA;EACA,gBAAA;EACA,mBAAA;EACA,aAAA;AACF;AAEA;EACE,YAAA;EACA,yBAAA;EAEA,aAAA;EACA,sBAAA;AADF;AAIA;EACE,yBAAA;EACA,WAAA;EACA,aAAA;EACA,kBAAA;AAFF","sourcesContent":[".viewWrapper {\n  height: calc(100% - 50px);\n  overflow: hidden;\n  margin-top: 50px;\n  background: #dce0e2;\n  display: flex;\n}\n\n.content {\n  height: 100%;\n  width: calc(100% - 248px);\n\n  display: flex;\n  flex-direction: column;\n}\n\n.contentTop {\n  height: calc(100% - 64px);\n  width: 100%;\n  display: flex;\n  padding-right: 7px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"viewWrapper": `index_viewWrapper__ihBSo`,
	"content": `index_content__oTq3e`,
	"contentTop": `index_contentTop__Xj5l-`
};
export default ___CSS_LOADER_EXPORT___;
