// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index_whiteboard-colorpicker__0\\+SNu {
  display: flex;
  align-items: center;
}
.index_colorItem__ECLrb {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-right: 6px;
  border: 1px solid #fff;
  border-radius: 2px;
}
.index_selectedColor__ZWQS4 {
  border: 1px solid #e3e3e3;
}
.index_selectedColor__ZWQS4::after {
  position: absolute;
  top: 4px;
  display: block;
  width: 10px;
  height: 6px;
  border-bottom: 1px solid #fff;
  border-left: 1px solid #fff;
  border-radius: 1px;
  transform: rotateZ(-45deg);
  content: '';
}
.index_colorBlock__SGJIE {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 2px;
}
`, "",{"version":3,"sources":["webpack://./src/components/WhiteBoard/SideToolBar/ColorPicker/index.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;AACF;AAEA;EACE,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;EACA,sBAAA;EACA,kBAAA;AAAF;AAGA;EACE,yBAAA;AADF;AAGE;EACE,kBAAA;EACA,QAAA;EACA,cAAA;EACA,WAAA;EACA,WAAA;EACA,6BAAA;EACA,2BAAA;EACA,kBAAA;EACA,0BAAA;EACA,WAAA;AADJ;AAKA;EACE,qBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;AAHF","sourcesContent":[".whiteboard-colorpicker {\n  display: flex;\n  align-items: center;\n}\n\n.colorItem {\n  position: relative;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 20px;\n  height: 20px;\n  margin-right: 6px;\n  border: 1px solid #fff;\n  border-radius: 2px;\n}\n\n.selectedColor {\n  border: 1px solid #e3e3e3;\n\n  &::after {\n    position: absolute;\n    top: 4px;\n    display: block;\n    width: 10px;\n    height: 6px;\n    border-bottom: 1px solid #fff;\n    border-left: 1px solid #fff;\n    border-radius: 1px;\n    transform: rotateZ(-45deg);\n    content: '';\n  }\n}\n\n.colorBlock {\n  display: inline-block;\n  width: 16px;\n  height: 16px;\n  border-radius: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"whiteboard-colorpicker": `index_whiteboard-colorpicker__0+SNu`,
	"colorItem": `index_colorItem__ECLrb`,
	"selectedColor": `index_selectedColor__ZWQS4`,
	"colorBlock": `index_colorBlock__SGJIE`
};
export default ___CSS_LOADER_EXPORT___;
