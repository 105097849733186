// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index_userListWrapper__B33Tf {
  width: 248px;
  height: 100%;
  padding: 18px 0;
  background: #f1f3f5;
  border-top: 1px solid #dce0e2;
  overflow-y: auto;
  overflow-x: hidden;
}
.index_userListTile__8178R {
  height: 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  padding: 0 16px;
  color: #1d2129;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}
.index_userListContent__lb3GC {
  height: calc(100% - 22px - 8px);
}
.index_iconClose__dvNqQ {
  cursor: pointer;
}
.index_iconClose__dvNqQ svg {
  fill: #d8d8d8;
}
.index_loadMore__O4fe2 {
  display: flex;
  justify-content: center;
  color: #4e5969;
  font-size: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/scene/Edub/Room/UserInfoList/index.module.less"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,YAAA;EACA,eAAA;EACA,mBAAA;EACA,6BAAA;EACA,gBAAA;EACA,kBAAA;AADF;AAIA;EACE,YAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,kBAAA;EACA,eAAA;EAEA,cAAA;EACA,0BAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;AAHF;AAMA;EACE,+BAAA;AAJF;AAOA;EACE,eAAA;AALF;AAIA;EAKM,aAAA;AANN;AAWA;EACE,aAAA;EACA,uBAAA;EACA,cAAA;EACA,eAAA;AATF","sourcesContent":["@import '~@/theme/color.less';\n\n.userListWrapper {\n  width: 248px;\n  height: 100%;\n  padding: 18px 0;\n  background: #f1f3f5;\n  border-top: 1px solid #dce0e2;\n  overflow-y: auto;\n  overflow-x: hidden;\n}\n\n.userListTile {\n  height: 22px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 8px;\n  padding: 0 16px;\n\n  color: #1d2129;\n  font-family: 'PingFang SC';\n  font-style: normal;\n  font-weight: 500;\n  font-size: 14px;\n}\n\n.userListContent {\n  height: calc(100% - 22px - 8px);\n}\n\n.iconClose {\n  cursor: pointer;\n\n  :global {\n    svg {\n      fill: #d8d8d8;\n    }\n  }\n}\n\n.loadMore {\n  display: flex;\n  justify-content: center;\n  color: #4e5969;\n  font-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"userListWrapper": `index_userListWrapper__B33Tf`,
	"userListTile": `index_userListTile__8178R`,
	"userListContent": `index_userListContent__lb3GC`,
	"iconClose": `index_iconClose__dvNqQ`,
	"loadMore": `index_loadMore__O4fe2`
};
export default ___CSS_LOADER_EXPORT___;
