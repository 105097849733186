// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index_menuButton__w-uDe {
  position: relative;
  width: 88px;
  height: 54px;
  margin-right: 8px;
  text-align: center;
  border-radius: 8px;
  -webkit-user-select: none;
          user-select: none;
}
.index_menuButton__w-uDe:hover {
  background: rgba(255, 255, 255, 0.05);
}
.index_userCount__jZDIM {
  position: absolute;
  top: 3px;
  left: 64%;
  font-size: 12px;
  color: var(--background-bottom-menu-icon);
  font-family: Inter;
}
.index_normalIcon__xt\\+hZ svg {
  fill: var(--background-bottom-menu-icon);
}
`, "",{"version":3,"sources":["webpack://./src/components/UserListButton/index.module.less"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,kBAAA;EACA,yBAAA;UAAA,iBAAA;AADF;AAGE;EACE,qCAAA;AADJ;AAKA;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,eAAA;EACA,yCAAA;EACA,kBAAA;AAHF;AAMA;EAGM,wCAAA;AANN","sourcesContent":["@import '~@/theme/color.less';\n\n.menuButton {\n  position: relative;\n  width: 88px;\n  height: 54px;\n  margin-right: 8px;\n  text-align: center;\n  border-radius: 8px;\n  user-select: none;\n\n  &:hover {\n    background: rgba(255, 255, 255, 5%);\n  }\n}\n\n.userCount {\n  position: absolute;\n  top: 3px;\n  left: 64%;\n  font-size: 12px;\n  color: var(--background-bottom-menu-icon);\n  font-family: Inter;\n}\n\n.normalIcon {\n  :global {\n    svg {\n      fill: var(--background-bottom-menu-icon);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuButton": `index_menuButton__w-uDe`,
	"userCount": `index_userCount__jZDIM`,
	"normalIcon": `index_normalIcon__xt+hZ`
};
export default ___CSS_LOADER_EXPORT___;
