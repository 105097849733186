// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index_roomInfo__iajf- {
  display: flex;
  align-items: center;
  height: 28px;
  padding: 3px 10px;
  color: var(--color-header-room-info);
  font-weight: 400;
  font-size: 12px;
  font-family: 'PingFang SC';
  font-style: normal;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  min-width: 150px;
}
.index_sperature__qjj7T {
  display: block;
  height: 12px;
  margin: 0 8px;
  border-left: 1px solid var(--color-header-room-sperature);
}
.index_record_wrap__oBNRA {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #e63f3f;
}
.index_record_wrap__oBNRA .index_record_wrapIcon__36ArF {
  display: flex;
  align-items: center;
  margin-right: 4px;
}
.index_record_wrap__oBNRA .index_record_wrapIcon__36ArF svg {
  width: 12px;
  height: 12px;
}
`, "",{"version":3,"sources":["webpack://./src/components/HeaderRoomInfo/index.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,YAAA;EACA,iBAAA;EACA,oCAAA;EACA,gBAAA;EACA,eAAA;EACA,0BAAA;EACA,kBAAA;EACA,qCAAA;EACA,kBAAA;EACA,gBAAA;AACF;AAEA;EACE,cAAA;EACA,YAAA;EACA,aAAA;EACA,yDAAA;AAAF;AAGA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,cAAA;AADF;AAHA;EAMI,aAAA;EACA,mBAAA;EACA,iBAAA;AAAJ;AARA;EAUM,WAAA;EACA,YAAA;AACN","sourcesContent":[".roomInfo {\n  display: flex;\n  align-items: center;\n  height: 28px;\n  padding: 3px 10px;\n  color: var(--color-header-room-info);\n  font-weight: 400;\n  font-size: 12px;\n  font-family: 'PingFang SC';\n  font-style: normal;\n  background: rgba(255, 255, 255, 5%);\n  border-radius: 8px;\n  min-width: 150px;\n}\n\n.sperature {\n  display: block;\n  height: 12px;\n  margin: 0 8px;\n  border-left: 1px solid var(--color-header-room-sperature);\n}\n\n.record_wrap {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: #e63f3f;\n  .record_wrapIcon {\n    display: flex;\n    align-items: center;\n    margin-right: 4px;\n    svg {\n      width: 12px;\n      height: 12px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"roomInfo": `index_roomInfo__iajf-`,
	"sperature": `index_sperature__qjj7T`,
	"record_wrap": `index_record_wrap__oBNRA`,
	"record_wrapIcon": `index_record_wrapIcon__36ArF`
};
export default ___CSS_LOADER_EXPORT___;
