// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index_divider__Adb-b {
  margin-top: 8px;
  margin-bottom: 8px;
}
.index_title__JBFyY {
  display: inline-block;
  height: 20px;
  margin-bottom: 6px;
  color: #1d2129;
  font-weight: 500;
  font-size: 12px;
  font-family: 'PingFang SC';
  font-style: normal;
  line-height: 20px;
}
.index_shapeDesc__sledq {
  color: #86909c;
  font-weight: 200;
  font-family: 'PingFang SC';
}
`, "",{"version":3,"sources":["webpack://./src/components/WhiteBoard/SideToolBar/PopModal/index.module.less"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,kBAAA;AACF;AAEA;EACE,qBAAA;EACA,YAAA;EACA,kBAAA;EACA,cAAA;EACA,gBAAA;EACA,eAAA;EACA,0BAAA;EACA,kBAAA;EACA,iBAAA;AAAF;AAGA;EACE,cAAA;EACA,gBAAA;EACA,0BAAA;AADF","sourcesContent":[".divider {\n  margin-top: 8px;\n  margin-bottom: 8px;\n}\n\n.title {\n  display: inline-block;\n  height: 20px;\n  margin-bottom: 6px;\n  color: #1d2129;\n  font-weight: 500;\n  font-size: 12px;\n  font-family: 'PingFang SC';\n  font-style: normal;\n  line-height: 20px;\n}\n\n.shapeDesc {\n  color: #86909c;\n  font-weight: 200;\n  font-family: 'PingFang SC';\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"divider": `index_divider__Adb-b`,
	"title": `index_title__JBFyY`,
	"shapeDesc": `index_shapeDesc__sledq`
};
export default ___CSS_LOADER_EXPORT___;
