// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index_menuWrapper__aQ4aA {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 64px;
  padding: 0 8px;
  background-color: var(--background-footer);
  border-right: 1px solid var(--background-container);
}
.index_menuLeft__M9ieC,
.index_menuMiddle__8EBeh,
.index_menuRight__sIJ5K {
  display: flex;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/scene/Edub/Room/BottomMenu/index.module.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,WAAA;EACA,YAAA;EACA,cAAA;EACA,0CAAA;EACA,mDAAA;AADF;AAIA;;;EAGE,aAAA;EACA,mBAAA;AAFF","sourcesContent":["@import '~@/theme/color.less';\n\n.menuWrapper {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n  height: 64px;\n  padding: 0 8px;\n  background-color: var(--background-footer);\n  border-right: 1px solid var(--background-container);\n}\n\n.menuLeft,\n.menuMiddle,\n.menuRight {\n  display: flex;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuWrapper": `index_menuWrapper__aQ4aA`,
	"menuLeft": `index_menuLeft__M9ieC`,
	"menuMiddle": `index_menuMiddle__8EBeh`,
	"menuRight": `index_menuRight__sIJ5K`
};
export default ___CSS_LOADER_EXPORT___;
