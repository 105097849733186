// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index_toast__5jgU5 {
  color: #1d2129;
  font-weight: 500;
  font-size: 14px;
  font-family: 'PingFang SC';
}
.index_toast__5jgU5 .ant-modal-content {
  padding: 32px 40px;
  border-radius: 12px;
}
.index_toast__5jgU5 .ant-modal-header {
  margin-bottom: 16px;
  padding: 0 !important;
  border: none !important;
}
.index_toast__5jgU5 .ant-modal-body {
  padding: 0 !important;
}
.index_toast__5jgU5 .ant-modal-body .ant-btn {
  width: 240px;
  height: 36px;
  margin-bottom: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Toast/index.module.less"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,gBAAA;EACA,eAAA;EACA,0BAAA;AACF;AALA;EAQM,kBAAA;EACA,mBAAA;AAAN;AATA;EAaM,mBAAA;EACA,qBAAA;EACA,uBAAA;AADN;AAdA;EAmBM,qBAAA;AAFN;AAjBA;EAsBQ,YAAA;EACA,YAAA;EACA,mBAAA;AAFR","sourcesContent":[".toast {\n  color: #1d2129;\n  font-weight: 500;\n  font-size: 14px;\n  font-family: 'PingFang SC';\n\n  :global {\n    .ant-modal-content {\n      padding: 32px 40px;\n      border-radius: 12px;\n    }\n\n    .ant-modal-header {\n      margin-bottom: 16px;\n      padding: 0 !important;\n      border: none !important;\n    }\n\n    .ant-modal-body {\n      padding: 0 !important;\n\n      .ant-btn {\n        width: 240px;\n        height: 36px;\n        margin-bottom: 15px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toast": `index_toast__5jgU5`
};
export default ___CSS_LOADER_EXPORT___;
