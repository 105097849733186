// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index_modalContent__sThCm {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.index_modalContent__sThCm .index_icon__AbAx9 {
  margin-right: 12px;
  font-size: 18px;
}
`, "",{"version":3,"sources":["webpack://./src/components/AudioAskModal/index.module.less"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;EACA,mBAAA;EACA,2BAAA;EACA,mBAAA;AACJ;AANA;EAQM,kBAAA;EACA,eAAA;AACN","sourcesContent":[".modalContent {\n    width: 100%;\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-start;\n    align-items: center;\n  \n    .icon {\n      margin-right: 12px;\n      font-size: 18px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalContent": `index_modalContent__sThCm`,
	"icon": `index_icon__AbAx9`
};
export default ___CSS_LOADER_EXPORT___;
