// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index_whiteBoard__2exQ3 {
  position: relative;
  flex: 1 1;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.index_whiteBoard__2exQ3 .canvas-container {
  width: 100%;
  /* border: 1px solid #c7c7c7; */
  height: 100%;
  margin: 0 auto;
  background-color: #fff;
  position: absolute !important;
}
.index_whiteBoard__2exQ3 #board {
  display: block;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  aspect-ratio: 16/9;
}
`, "",{"version":3,"sources":["webpack://./src/components/WhiteBoard/index.module.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,SAAA;EACA,YAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;AARA;EAWM,WAAA;EAAJ,+BAA+B;EAG3B,YAAA;EACA,cAAA;EACA,sBAAA;EACA,6BAAA;AADN;AAhBA;EAqBM,cAAA;EACA,YAAA;EACA,WAAA;EACA,cAAA;EACA,kBAAA;AAFN","sourcesContent":[".whiteBoard {\n  position: relative;\n  flex: 1;\n  height: 100%;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  :global {\n    .canvas-container {\n      width: 100%;\n\n      /* border: 1px solid #c7c7c7; */\n      height: 100%;\n      margin: 0 auto;\n      background-color: #fff;\n      position: absolute !important;\n    }\n\n    #board {\n      display: block;\n      height: 100%;\n      width: 100%;\n      margin: 0 auto;\n      aspect-ratio: 16/9;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"whiteBoard": `index_whiteBoard__2exQ3`
};
export default ___CSS_LOADER_EXPORT___;
