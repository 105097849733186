// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index_shareViewWrapper__6vU\\+G {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.index_shareWrapper__0o9vr {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 36px;
  height: 100%;
}
.index_stopButton__PbtfJ {
  display: block;
  width: 108px;
  height: 32px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  background: #f53f3f;
  border-radius: 4px;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  color: #ffffff;
}
.index_shareDom__ego9h {
  width: 100%;
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/scene/Edub/Room/EdubView/ScreenView/index.module.less"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;AADF;AAIA;EACE,aAAA;EACA,SAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,eAAA;EACA,YAAA;AAFF;AAKA;EACE,cAAA;EACA,YAAA;EACA,YAAA;EACA,oBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,mBAAA;EACA,kBAAA;EACA,eAAA;EACA,yBAAA;UAAA,iBAAA;EACA,cAAA;AAHF;AAMA;EACE,WAAA;EACA,YAAA;AAJF","sourcesContent":["@import '~@/theme/color.less';\n\n.shareViewWrapper {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n}\n\n.shareWrapper {\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  color: #fff;\n  font-size: 36px;\n  height: 100%;\n}\n\n.stopButton {\n  display: block;\n  width: 108px;\n  height: 32px;\n  font-family: 'Inter';\n  font-style: normal;\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 32px;\n  text-align: center;\n  background: #f53f3f;\n  border-radius: 4px;\n  cursor: pointer;\n  user-select: none;\n  color: #ffffff;\n}\n\n.shareDom {\n  width: 100%;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"shareViewWrapper": `index_shareViewWrapper__6vU+G`,
	"shareWrapper": `index_shareWrapper__0o9vr`,
	"stopButton": `index_stopButton__PbtfJ`,
	"shareDom": `index_shareDom__ego9h`
};
export default ___CSS_LOADER_EXPORT___;
