// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index_chooseShape__8UCtK {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}
.index_item__O3zPk {
  width: 28px;
  height: 28px;
  margin-right: 10px;
}
.index_active__I7L9u {
  background: #e1edfe;
}
`, "",{"version":3,"sources":["webpack://./src/components/WhiteBoard/SideToolBar/ChartChoose/index.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,gBAAA;EACA,mBAAA;AACF;AAEA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;AAAF;AAGA;EACE,mBAAA;AADF","sourcesContent":[".chooseShape {\n  display: flex;\n  margin-top: 10px;\n  margin-bottom: 10px;\n}\n\n.item {\n  width: 28px;\n  height: 28px;\n  margin-right: 10px;\n}\n\n.active {\n  background: #e1edfe;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chooseShape": `index_chooseShape__8UCtK`,
	"item": `index_item__O3zPk`,
	"active": `index_active__I7L9u`
};
export default ___CSS_LOADER_EXPORT___;
