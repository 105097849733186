// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index_toolbarIcon__-rhS\\+ {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}
.index_toolbarIcon__-rhS\\+ svg {
  fill: var(--background-toolbar-icon);
}
.index_toolbarIconButton__eFb5b {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  text-align: center;
  border-radius: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/components/WhiteBoard/SideToolBar/IconBtn/index.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;AACF;AALA;EAQM,oCAAA;AAAN;AAKA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,kBAAA;AAHF","sourcesContent":[".toolbarIcon {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  font-size: 16px;\n\n  :global {\n    svg {\n      fill: var(--background-toolbar-icon);\n    }\n  }\n}\n\n.toolbarIconButton {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 32px;\n  height: 32px;\n  text-align: center;\n  border-radius: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toolbarIcon": `index_toolbarIcon__-rhS+`,
	"toolbarIconButton": `index_toolbarIconButton__eFb5b`
};
export default ___CSS_LOADER_EXPORT___;
