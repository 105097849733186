// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index_loadingWrapper__tnp7p {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.index_loadingWrapper__tnp7p img {
  width: 36px;
  height: 36px;
  margin-bottom: 8px;
}
.index_text__VAogc {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0.003em;
  color: #41464f;
}
`, "",{"version":3,"sources":["webpack://./src/components/Loading/index.module.less"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;AACF;AAPA;EASM,WAAA;EACA,YAAA;EACA,kBAAA;AACN;AAIA;EACE,0BAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,uBAAA;EAEA,cAAA;AAHF","sourcesContent":[".loadingWrapper {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  :global {\n    img {\n      width: 36px;\n      height: 36px;\n      margin-bottom: 8px;\n    }\n  }\n}\n\n.text {\n  font-family: 'PingFang SC';\n  font-style: normal;\n  font-weight: 400;\n  font-size: 13px;\n  line-height: 22px;\n  letter-spacing: 0.003em;\n\n  color: #41464f;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingWrapper": `index_loadingWrapper__tnp7p`,
	"text": `index_text__VAogc`
};
export default ___CSS_LOADER_EXPORT___;
