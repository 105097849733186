// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index_pagination__7aZ4C {
  display: flex;
  align-items: center;
  height: 18px;
  color: #fff;
  font-size: 13px;
  background: transparent;
}
.index_pageBtn__besCf {
  width: 24px;
  background: transparent;
  border: none;
  box-shadow: none;
}
.index_pageBtnDisable__wJ2ce {
  opacity: 0.4;
}
.index_pageBtnNext__F\\+l36 svg {
  transform: rotateZ(180deg);
}
.index_pageInfo__B75xp {
  display: flex;
  align-items: center;
  height: 18px;
  line-height: 18px;
}
.index_pageInfoActive__GeGP0 {
  width: 36px;
  height: 18px;
  line-height: 18px;
  text-align: right;
}
.index_pageInfoSplit__oTOnR {
  height: 18px;
  line-height: 18px;
}
.index_pageInfoNext__meSSb {
  width: 36px;
  height: 18px;
  line-height: 18px;
  text-align: left;
}
`, "",{"version":3,"sources":["webpack://./src/components/Pagination/index.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,YAAA;EACA,WAAA;EACA,eAAA;EACA,uBAAA;AACF;AAEA;EACE,WAAA;EACA,uBAAA;EACA,YAAA;EACA,gBAAA;AAAF;AAGA;EACE,YAAA;AADF;AAIA;EAGM,0BAAA;AAJN;AASA;EACE,aAAA;EACA,mBAAA;EACA,YAAA;EACA,iBAAA;AAPF;AAUA;EACE,WAAA;EACA,YAAA;EACA,iBAAA;EACA,iBAAA;AARF;AAWA;EACE,YAAA;EACA,iBAAA;AATF;AAYA;EACE,WAAA;EACA,YAAA;EACA,iBAAA;EACA,gBAAA;AAVF","sourcesContent":[".pagination {\n  display: flex;\n  align-items: center;\n  height: 18px;\n  color: #fff;\n  font-size: 13px;\n  background: transparent;\n}\n\n.pageBtn {\n  width: 24px;\n  background: transparent;\n  border: none;\n  box-shadow: none;\n}\n\n.pageBtnDisable {\n  opacity: 0.4;\n}\n\n.pageBtnNext {\n  :global {\n    svg {\n      transform: rotateZ(180deg);\n    }\n  }\n}\n\n.pageInfo {\n  display: flex;\n  align-items: center;\n  height: 18px;\n  line-height: 18px;\n}\n\n.pageInfoActive {\n  width: 36px;\n  height: 18px;\n  line-height: 18px;\n  text-align: right;\n}\n\n.pageInfoSplit {\n  height: 18px;\n  line-height: 18px;\n}\n\n.pageInfoNext {\n  width: 36px;\n  height: 18px;\n  line-height: 18px;\n  text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pagination": `index_pagination__7aZ4C`,
	"pageBtn": `index_pageBtn__besCf`,
	"pageBtnDisable": `index_pageBtnDisable__wJ2ce`,
	"pageBtnNext": `index_pageBtnNext__F+l36`,
	"pageInfo": `index_pageInfo__B75xp`,
	"pageInfoActive": `index_pageInfoActive__GeGP0`,
	"pageInfoSplit": `index_pageInfoSplit__oTOnR`,
	"pageInfoNext": `index_pageInfoNext__meSSb`
};
export default ___CSS_LOADER_EXPORT___;
