// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index_menuIconButton__-1v3j {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 54px;
  cursor: pointer;
}
.index_iconWrapper__Dh-G1 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  padding-top: 5px;
  border-radius: 100px;
  -webkit-user-select: none;
          user-select: none;
}
.index_menuButtonText__OJeZ9 {
  color: var(--color-bottom-menu-text);
  font-weight: 400;
  font-size: 12px;
  font-family: Inter;
  font-style: normal;
  opacity: 0.6;
}
`, "",{"version":3,"sources":["webpack://./src/components/MenuIconButton/index.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,eAAA;AACF;AAEA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;EACA,oBAAA;EACA,yBAAA;UAAA,iBAAA;AAAF;AAGA;EACE,oCAAA;EACA,gBAAA;EACA,eAAA;EACA,kBAAA;EACA,kBAAA;EACA,YAAA;AADF","sourcesContent":[".menuIconButton {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  height: 54px;\n  cursor: pointer;\n}\n\n.iconWrapper {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 24px;\n  height: 24px;\n  padding-top: 5px;\n  border-radius: 100px;\n  user-select: none;\n}\n\n.menuButtonText {\n  color: var(--color-bottom-menu-text);\n  font-weight: 400;\n  font-size: 12px;\n  font-family: Inter;\n  font-style: normal;\n  opacity: 0.6;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuIconButton": `index_menuIconButton__-1v3j`,
	"iconWrapper": `index_iconWrapper__Dh-G1`,
	"menuButtonText": `index_menuButtonText__OJeZ9`
};
export default ___CSS_LOADER_EXPORT___;
