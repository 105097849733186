// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index_linkButton__X\\+PXM {
  position: absolute;
  right: 20px;
  bottom: 20px;
  background: #ffffff;
  border: 1px solid #dde2e9;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  -webkit-user-select: none;
          user-select: none;
}
.index_notlink__4BjWj,
.index_applying__J-N0\\+,
.index_linking__QPwD5 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 8px;
}
.index_notlink__4BjWj span,
.index_applying__J-N0\\+ span,
.index_linking__QPwD5 span {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}
.index_notlink__4BjWj span {
  color: #4e5969;
}
.index_notlink__4BjWj svg {
  fill: #41464f;
}
.index_applying__J-N0\\+ span {
  color: #4086ff;
}
.index_applying__J-N0\\+ svg {
  fill: #4281ff;
}
.index_linking__QPwD5 span {
  color: #1ac84b;
}
.index_linking__QPwD5 svg {
  fill: #22bb43;
}
`, "",{"version":3,"sources":["webpack://./src/scene/Edub/components/LinkButton/index.module.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EAEA,yBAAA;EAEA,WAAA;EACA,YAAA;EACA,kBAAA;EAEA,yBAAA;UAAA,iBAAA;AAFF;AAKA;;;EAGE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;EACA,gBAAA;AAHF;AAJA;;;EAWM,0BAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;AAFN;AAOA;EAGM,cAAA;AAPN;AAIA;EAOM,aAAA;AARN;AAaA;EAGM,cAAA;AAbN;AAUA;EAOM,aAAA;AAdN;AAmBA;EAGM,cAAA;AAnBN;AAgBA;EAOM,aAAA;AApBN","sourcesContent":[".linkButton {\n  position: absolute;\n  right: 20px;\n  bottom: 20px;\n  background: #ffffff;\n\n  border: 1px solid #dde2e9;\n\n  width: 80px;\n  height: 80px;\n  border-radius: 50%;\n\n  user-select: none;\n}\n\n.notlink,\n.applying,\n.linking {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  padding-top: 8px;\n\n  :global {\n    span {\n      font-family: 'PingFang SC';\n      font-style: normal;\n      font-weight: 400;\n      font-size: 12px;\n    }\n  }\n}\n\n.notlink {\n  :global {\n    span {\n      color: #4e5969;\n    }\n\n    svg {\n      fill: #41464f;\n    }\n  }\n}\n\n.applying {\n  :global {\n    span {\n      color: #4086ff;\n    }\n\n    svg {\n      fill: #4281ff;\n    }\n  }\n}\n\n.linking {\n  :global {\n    span {\n      color: #1ac84b;\n    }\n\n    svg {\n      fill: #22bb43;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"linkButton": `index_linkButton__X+PXM`,
	"notlink": `index_notlink__4BjWj`,
	"applying": `index_applying__J-N0+`,
	"linking": `index_linking__QPwD5`
};
export default ___CSS_LOADER_EXPORT___;
